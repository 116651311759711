.innerblog-page-content .blog-loading {
    margin-top: -95px;
}

.blog-post-img {
    height: 250px;
    background: #efebf1;
    margin: 20px 0;
    border-bottom: 4px solid #a33469;
}

.blog-post-img img {
    height: 100%;
    width: auto;
    margin: auto;
}

.blog-text img {
    max-width: 500px;
    height: auto;
}

@media (max-width: 768px) {
    .blog-post-img {
        height: auto;
    }

    .blog-post-img img {
        width: 100%;
        height: auto;
    }
}
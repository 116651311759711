footer {
	background: #0D0011;
	color: #fff;
	position: absolute;
	width: 100%;
	bottom: 0;
}

footer a {
	color: #B96890;
}

footer a:hover {
	color: #A3346A;
}

footer ul {
	list-style: none;
	padding: 0;
}

footer .social {
	text-align: right;
}

footer .social a {
	color: #fff;
	background: #38004d;
	padding: 7px;
	width: 35px;
	height: 35px;
	display: inline-block;
	text-align: center;
	margin-left: 5px;
	border-radius: 50px;
}

footer .social a:hover {
	background: #492954;
}

footer .btn-primary {
	display: inline-block;
	margin-top: 10px;
	color: #fff;
}

footer h4 {
	color: #ffffff;
}

footer .copyright {
	text-align: right;
	margin-top: 20px;
    font-weight: 300;
}

@media (max-width: 768px) {
	footer {
		text-align: center;
		position: static;
	}

    footer .social {
        text-align: center;
		margin-top: 20px;
    }

    footer .copyright {
        text-align: center;
    }
}

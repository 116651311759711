.portfolio-archives .row:nth-child(odd) section section {
	float: right;
}

.portfolio-archives a:hover img {
	opacity: 0.5;
	transition: all 0.3s linear;
}

.portfolio-row {
	background-color: #efebf1;
}

.portfolio-img {
	display: block;
	height: 285px;
	background: #bdb8c1;
}

.portfolio-img img {
	height: 100%;
	width: auto;
	margin: auto;
}

.portfolio-archive {
	padding: 20px;
}

.portfolio-archive h2 {
	margin-top: 0;
}

.portfolio-archive a {
	margin-top: 20px;
}

.portfolio-archives .container {
	position: relative;
}

.portfolio-archives .row section section {
	padding: 0;
}

.portfolio-loading {
	text-align: center;
	position: absolute;
	width: 100%;
	left: 0;
	top: 50%;
	margin-top: -25px;
}

.portfolio-loading .fa-spin {
	font-size: 50px;
}

@media (max-width: 768px) {
	.portfolio-archives .row:nth-child(odd) section section {
		float: none;
	}

	.portfolio-img {
		height: auto;
	}

	.portfolio-img img {
		width: 100%;
		height: auto;
	}
}

.truncatedText {
    white-space: nowrap;
    width: 250px;/* OR as much space that allows */
    overflow: hidden;
    text-overflow: ellipsis;
}

.better-together {
    background: #fafafa;
}

.why-us section .fa {
	color: #fff;
	padding: 9px;
	float: left;
	background: #38004d;
	margin-right: 15px;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	text-align: center;
}

.why-us section h3 {
    margin-top: 3px;
	margin-bottom: 20px;
}

@media (max-width: 768px) {
    .why-us section p {
        border-bottom: 1px solid #bdb8c1;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .why-us section:last-child p {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .better-together img {
        display: none;
    }
}

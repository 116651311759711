.here-for-you {
    border-top: 1px solid #efebf1;
}

.here-for-you h4 {
    margin-bottom: 20px;
    margin-top: 20px;
}

.here-for-you .btn {
    margin-bottom: 10px;
}

.devops-services.row-block {
    padding-bottom: 0;
    background: #fafafa;
}

.devops-services .tile {
    padding: 60px 40px;
    text-align: center;
    background: #fff;
    margin-bottom: 40px;
    min-height: 327px;
    border-radius: 10px;
    transition: all linear 0.3s;
}

.devops-services .tile:hover {
    transform: scale(1.05);
}

.devops-services .tile i {
    font-size: 40px;
    display: block;
    margin-bottom: 25px;
    color: #a33468;
}

.devops-services .tile h3 {
    font-size: 18px;
    color: #a33468;
    font-weight: 500;
}

@media (max-width: 768px) {
    .our-services .tile {
        min-height: 0;
    }
}
.bg-light {
    transition: background linear 0.2s;
}

.bg-light.light {
    background: transparent!important;
}

.bg-light.dark {
    background: #fafafa!important;
    border-bottom: 2px solid #a3346a;
    /*box-shadow: 0 0 10px 1px #aaa;*/
}

.logo-main {
	max-width: 200px;
	height: auto;
	padding: 10px 0;
}

/*.light .logo-main {
    filter: brightness(0) invert(1);
}*/

.navbar-light .navbar-nav .nav-link {
	color: #38004d;
	font-family: 'Saira', Arial, sans-serif;
	font-weight: 500;
	font-size: 18px;
    padding: 0.3rem 1.3rem;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: #a3346a;
}

.navbar-light .navbar-nav .nav-link.contact {
    border-radius: 50px;
    background-color: #a3346a;
    color: #fff;
}

.navbar-light .navbar-nav .nav-link.contact:hover,
.navbar-light .navbar-nav .nav-link.contact:focus {
	background-color: #B96890;
	color: #fff;
}

.navbar-light.dark .navbar-nav .nav-link {
    color: #38004d;
}

.navbar-light.dark .navbar-nav .nav-link:hover,
.navbar-light.dark .navbar-nav .nav-link.active {
    color: #a3346a;
}

.navbar-light.dark .navbar-nav .nav-link.contact {
    color: #fff;
}

.navbar-light.dark .navbar-nav .nav-link.login:hover {
    border-color: #B96890;
}

.navbar-right {
    margin-top: 20px;
}

.navbar-light.light .navbar-toggler {
    border-color: #a3346a;
}

.navbar-light.light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(163, 52, 106, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light.dark .navbar-toggler {
    border-color: #a3346a;
}

.navbar-light.dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(163, 52, 106, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media (max-width: 992px) {
    .navbar-light .navbar-nav .nav-link {
		padding-right: 10px;
		padding-left: 10px;
        font-size: 13px;
	}
}

@media (max-width: 768px) {
    .navbar-light .navbar-nav .nav-link {
        font-size: 18px;
    }

    .navbar-light.light .navbar-nav .nav-link {
        background-color: #fafafa;
    }

    .navbar-light.light .navbar-nav .nav-link.contact {
        background-color: #a3346a;
        border-radius: 0 0 10px 10px;
    }

	.navbar-right {
		margin-bottom: 0;
	}

	.navbar-default .navbar-collapse {
		overflow: hidden!important;
	}

    .navbar-default .navbar-nav>li>a {
        padding-right: 15px;
        padding-left: 15px;
    }
}

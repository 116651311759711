.our-services.row-block {
    padding-bottom: 0;
}

.our-services .tile {
    padding: 60px 40px;
    text-align: center;
    border: 1px solid #efebf1;
    margin-bottom: 40px;
    min-height: 400px;
    border-radius: 10px;
    transition: all linear 0.3s;
}

.our-services .tile:hover {
    transform: scale(1.05);
}

.our-services .tile i {
    font-size: 40px;
    color: #A3346A;
    margin-bottom: 15px;
}

.our-services .tile h3 {
    font-size: 24px;
    color: #38004d;
}

@media (max-width: 768px) {
	.our-services .tile {
        min-height: 0;
    }
}
html {
	height: 100%;
}

body {
    font-family: 'Lato', Arial, sans-serif;
    font-size: 16px;
	min-height: 100%;
	position: relative;
    color: #0D0011;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Saira', Arial, sans-serif;
}

h1, h2, h3 {
    color: #A33468;
}

h4, h5, h6 {
    color: #38004d;
}

h2 {
    margin-bottom: 40px;
}

h2:after {
    content: "";
    display: block;
    height: 2px;
    width: 100px;
    background: #38004d;
    margin-top: 20px;
}

a {
    color: #a3346a;
}

a:focus, a:hover {
    color: #B96890;
    text-decoration: none;
	transition: all 0.3s linear;
}

.btn {
    border-radius: 50px;
}

.btn-primary {
    color: #fff;
    background-color: #A3346A;
    border-color: #A3346A;
    padding: 8px 20px;
    text-transform: uppercase;
	transition: all 0.3s linear;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.focus, .btn-primary:focus, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #B96890;
    border-color: #B96890;
}

.btn:focus,
.btn.focus,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.page-content {
	padding-bottom: 174px;
}

.row-block {
    padding: 40px 0;
}

.landing-strip {
    background: url("../../pages/Home/home-bg.jpg") no-repeat center #e6e6e6;
    background-attachment: fixed;
    background-size: cover;
    padding: 120px 0 40px;
}

.landing-strip h1 {
    font-size: 50px;
    color: #38004D;
    margin-top: 0;
}

.landing-strip p {
    font-size: 20px;
}

.fa-spin {
	font-size: 16px;
	color: #38004D;
}

@media (max-width: 768px) {
	.page-content {
		padding-bottom: 0;
		min-height: 600px;
	}

    .landing-strip h1 {
        font-size: 30px;
    }

    .landing-strip p {
        font-size: 15px;
    }

    h2 {
        margin-top: 0;
    }
}

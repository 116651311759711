.home-page-content {
    position: relative;
}

.home-page-content video {
    width: 100%;
    height: auto;
}

.home-page-content .landing-info {
    /*position: absolute;
    top: 0;
    width: 100%;
    padding-top: 10%;*/
    background: url("./home-bg.jpg") no-repeat center #e6e6e6;
    background-attachment: fixed;
    background-size: cover;
    padding: 150px 0;
}

.home-page-content .landing-info h1 {
    color: #38004d;
}

.home-page-content .landing-info h1 {
    margin-top: 60px;
    font-size: 70px;
}

.home-page-content .landing-info p {
    margin-bottom: 40px;
    font-size: 18px;
}

.home-about {
    background: #fafafa;
}

.home-blog {
    background: url("./blog-bg.jpg") no-repeat center;
    background-attachment: fixed;
    background-size: cover;
    padding: 100px 0;
}

.home-blog h2 {
    color: #fff;
}

.home-blog h2:after {
    background: #a33469;
}

.latest-blog {
    background: rgba(255, 255, 255, 0.70);
    padding: 40px;
}

.latest-blog h3 {
    color: #38004d;
    margin-top: 0;
}

.latest-blog p {
    margin-bottom: 20px;
}

@media (max-width: 1500px) {
    .home-page-content .landing-info {
        padding-top: 7%;
    }
}

@media (max-width: 1200px) {
    .home-page-content .landing-info {
        padding-top: 5%;
    }
}

@media (max-width: 992px) {
    .home-page-content .landing-info h1 {
        font-size: 40px;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .home-page-content video {
        display: none;
    }

    .home-page-content .landing-info {
        position: static;
        padding-top: 120px;
        padding-bottom: 80px;
        text-align: center;
    }

    .home-page-content .landing-info img {
        display: none;
    }

    .home-page-content .landing-info h1 {
        font-size: 30px;
        margin-top: 0;
    }

    .home-page-content .landing-info p {
        margin-bottom: 20px;
        font-size: 15px;
    }
}

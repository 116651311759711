.blog-archives .row:nth-child(odd) section section {
	float: right;
}

.blog-archives a:hover img {
	opacity: 0.5;
	transition: all 0.3s linear;
}

.blog-row {
	background-color: #efebf1;
}

.blog-img {
	display: block;
	height: 285px;
	background: #e3dee5;
	overflow: hidden;
}

.blog-img img {
	width: 100%;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.blog-archive {
	padding: 20px;
}

.blog-archive h2 {
	margin-top: 0;
}

.blog-archive a {
	margin-top: 20px;
}

.blog-archives .container {
	position: relative;
}

.blog-archives .row section section {
	padding: 0;
}

.blog-loading {
	text-align: center;
	position: absolute;
	width: 100%;
	left: 0;
	top: 50%;
	margin-top: -25px;
}

.blog-loading .fa-spin {
	font-size: 50px;
}

@media (max-width: 768px) {
	.blog-archives .row:nth-child(odd) section section {
		float: none;
	}

	.blog-img {
		height: auto;
	}

	.blog-img img {
		top: auto;
		left: auto;
		transform: none;
	}
}

.truncatedText {
    white-space: nowrap;
    width: 250px;/* OR as much space that allows */
    overflow: hidden;
    text-overflow: ellipsis;
}
